/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import {
	useOrderDetailsQuery,
	useOrderDetailsFromTradeQuery,
	useOrderDetailsFromCounterQuery,
} from 'src/_api';
import { useAllPaperPresets } from 'src/components/SearchHeader/useAllPaperPresets';
import { Environment, PaperPorts, PriceType, DrawerPriceType } from 'src/constants/contract';
import { paranaguaDefaultVolume } from './paper/model';

export const useCopyDetails = ({
	environment,
	orderId,
	negotiationId,
	tradeId,
	forcedOrderParams = {},
}) => {
	const tradeResult = useOrderDetailsFromTradeQuery(
		{ tradeId },
		{
			enabled: !!tradeId,
			select: data => {
				data.environment = Environment.OTC;
				data.has_price = true;
				return data;
			},
		}
	);

	const orderResult = useOrderDetailsQuery(
		{ environment, orderId },
		{
			enabled: !!orderId,
		}
	);

	const counterResult = useOrderDetailsFromCounterQuery(
		{ environment, orderId, negotiationId },
		{
			enabled: !!negotiationId,
			select: data => {
				data.environment = Environment.OTC;
				data.has_price = true;
				data.hidden = orderResult.data?.hidden;

				return data;
			},
		}
	);

	const hasForcedParams = Object.keys(forcedOrderParams).length > 0;

	const presetsResults = useAllPaperPresets(hasForcedParams);

	if (negotiationId) {
		return counterResult;
	}

	if (orderId) {
		return orderResult;
	}

	if (tradeId) {
		return tradeResult;
	}

	if (hasForcedParams) {
		const preset = presetsResults.data.presets.find(preset => {
			return (
				preset.product_id === forcedOrderParams.product_id &&
				preset.inco_id === forcedOrderParams.inco_id &&
				(preset.discharging_port_id === forcedOrderParams.primary_port_id ||
					preset.loading_port_id === forcedOrderParams.primary_port_id)
			);
		});

		return {
			data: {
				...forcedOrderParams,
				...getPresetOptions(preset),
			},
			isLoading: false,
		};
	}

	return {};
};

const getPresetOptions = preset => {
	if (!preset) {
		return {};
	}

	const isParanagua = preset.loading_port?._key === PaperPorts.PARANAGUA;

	return {
		preset_id: preset._key,
		volume: isParanagua
			? paranaguaDefaultVolume
			: preset.base_quantity || preset.volume || undefined,
		has_price: true,
		hidden: true,
		is_indicative: true,
		price_type: isParanagua ? PriceType.Basis : preset.price_type || undefined,
		futures_contract: isParanagua ? DrawerPriceType.CBOT : preset.futures_contract || undefined,
		runs: preset.runs ? 1 : undefined,
	};
};
