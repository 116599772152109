/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { useLocationStateParam } from 'src/_helpers/router';
import { config } from './config';
import { useAppTour } from './useAppTour';
import { TourSlides } from './TourSlides';
import { WelcomePopup } from './WelcomePopup';
import { isHeadlessRoute } from 'src/constants/routes';

export const AppTour = () => {
	const [isShowingWelcomePopup, setShowingWelcomePopup] = useState(true);
	const shouldShowTour = useAppTour(config.version);

	const tourOpenRequestedByUser = useLocationStateParam('showAppTour') || false;

	const handleButtonClick = () => setShowingWelcomePopup(false);

	const { pathname } = useLocation();

	const shouldSkipProductTourOnRoute = isHeadlessRoute(pathname);

	if (shouldSkipProductTourOnRoute) {
		return null;
	}

	if (tourOpenRequestedByUser || shouldShowTour) {
		return !tourOpenRequestedByUser && isShowingWelcomePopup ? (
			<WelcomePopup onClick={handleButtonClick} />
		) : (
			<TourSlides slides={config.slides} />
		);
	}

	return null;
};
