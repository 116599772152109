/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { useTranslation } from 'react-i18next';
import { isExpired } from 'src/_helpers/date';
import { useOrderDetails } from 'src/_helpers/useOrderDetails';
import { tOrderType, PaperInstruments } from 'src/constants/contract';
import { CounterStatus } from 'src/constants/counterStatus';
import { OrderStatus } from 'src/constants/orderStatus';

export const HeaderType = {
	Default: 'default',
	MyOrder: 'my_order',
	Info: 'info',
	Success: 'success',
	Warning: 'warning',
};

export const useViewOrderHeader = (order, counters = []) => {
	const { t } = useTranslation();
	const {
		isMyOrder,
		isMyCompanyOrder,
		isBrokerOrder,
		isMyTeamOrder,
		isOrderReceivedByTeamMember,
		isTraded,
	} = useOrderDetails(order);

	const { order_type: orderType, instrument } = order;
	const lastCounter = counters.at(-1);

	const isOrderTerminated = order.status === OrderStatus.Terminated;
	const isOrderExpired = isExpired(order.validity) || order.status === OrderStatus.Expired;
	const isOrderNotAvailable = order.status === OrderStatus.NotAvailable;
	const isAcceptedNoCounter =
		!isMyOrder && lastCounter?.status === CounterStatus.AcceptedNoCounter && !isOrderExpired;

	const isOrderTerminatedWithCounterConfirmed =
		isOrderTerminated && lastCounter?.status === CounterStatus.Confirmed;

	const shouldShowOrderWithdrawnToast =
		isOrderTerminated && !isOrderTerminatedWithCounterConfirmed;

	const shouldShowExpiredToast = isOrderExpired && !isTraded;

	const shouldShowOrderNoLongerAvailableToast =
		isOrderNotAvailable && !shouldShowExpiredToast && !isTraded;

	if (shouldShowOrderWithdrawnToast) {
		return {
			type: HeaderType.Info,
			message: t('order_withdrawn', {
				orderType: tOrderType(t, orderType, instrument),
			}),
		};
	}

	if (shouldShowOrderNoLongerAvailableToast) {
		return {
			type: HeaderType.Info,
			message: t('order_no_longer_available', {
				orderType: tOrderType(t, orderType, instrument),
			}),
		};
	}

	if (shouldShowExpiredToast) {
		if (isMyOrder) {
			return {
				type: HeaderType.Info,
				message: t('order_expired', {
					orderType: tOrderType(t, orderType, instrument),
				}),
			};
		}

		return {
			type: HeaderType.Info,
			message: t('order_no_longer_available', {
				orderType: tOrderType(t, orderType, instrument),
			}),
		};
	}

	if (isAcceptedNoCounter) {
		return {
			type: HeaderType.Info,
			message: t('order_awaiting_counterparty_response', {
				orderType: tOrderType(t, orderType, instrument),
				counterpartyType: (isBrokerOrder ? t('broker') : t('counterparty')).toLowerCase(),
			}),
		};
	}

	if (isMyOrder) {
		return {
			type: HeaderType.MyOrder,
			message: t('order_details', {
				orderType: tOrderType(t, orderType, instrument, PaperInstruments.Spread),
			}),
		};
	}

	if (isMyTeamOrder) {
		return {
			type: HeaderType.Info,
			message: t('order_created_by_your_team', {
				orderType: tOrderType(t, orderType, instrument),
			}),
		};
	}

	if (isMyCompanyOrder) {
		return {
			type: HeaderType.Info,
			message: t('order_from_your_company', {
				orderType: tOrderType(t, orderType, instrument),
			}),
		};
	}

	if (isOrderReceivedByTeamMember) {
		return {
			type: HeaderType.Info,
			message: t('order_received_by_your_team', {
				orderType: tOrderType(t, orderType, instrument),
			}),
		};
	}

	return {
		type: HeaderType.Default,
		message: t('order_details', {
			orderType: tOrderType(t, orderType, instrument, PaperInstruments.Spread),
		}),
	};
};
