/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { MaterialSelectInput } from 'src/designSystem/Form/MaterialInput/MaterialSelectInput';
import { PaperInstruments } from 'src/constants/contract';
import { useContractAndPricingSection } from './useContractAndPricingSection';
import { useWatchPaperFields } from '../../useWatchPaperFields';
import {
	SectionMeta,
	Title,
} from 'src/components/CreateOrderDrawer/components/FoldableSection/styled';
import { toPath } from 'src/_helpers';
import { ReactComponent as DeleteIcon } from 'src/assets/icons/delete.svg';
import { TotalQuantity } from 'src/components/CreateOrderDrawer/components/TotalQuantity/TotalQuantity';
import { MaterialMultiSelectInput } from 'src/designSystem/Form/MaterialInput/MaterialMultiSelectInput';
import {
	SectionGridContent,
	SectionGridLabel,
	SectionGridText,
} from 'src/components/ViewOrderDrawer/styled';
import { tEnvironment } from 'src/constants/contract';
import { CounterpartiesSection } from 'src/components/ViewOrderDrawer/Content/CounterpartiesSection/CounterpartiesSection';
import { UpwardOptionsWrapper } from 'src/designSystem/Form/MultiSelect/styled';
import { OutrightRow } from './OutrightRow';
import { CalendarSpreadRow } from './CalendarSpreadRow';
import * as Styled from './styled';

export const SingleOrderRow = ({ canRemove, onRemove, path, showRowNumbers, rowNumber }) => {
	const { t } = useTranslation();

	const { instrumentValue } = useWatchPaperFields(path);
	const { getValues } = useFormContext();

	const title = showRowNumbers ? `${t('quote')} ${rowNumber}` : t('quote');

	const { typeValue, environmentValue } = useWatchPaperFields(path);

	const {
		orderType,
		environment,
		nameVisibility,
		counterparties,
		role,
		shouldDisplayTotalQuantity,
		shouldShowReadOnlyCounterparties,
	} = useContractAndPricingSection({ path });

	return (
		<>
			<SectionMeta>
				<Title>{title}</Title>
			</SectionMeta>
			<div>
				<Styled.FieldContainer>
					{instrumentValue === PaperInstruments.Outright && <OutrightRow path={path} />}
					{instrumentValue === PaperInstruments.Spread && (
						<CalendarSpreadRow path={path} />
					)}
					<Styled.FieldsSeparator />
					<Controller
						name={toPath(path, 'orderType')}
						mandatory
						render={({ field, fieldState: { error } }) => (
							<MaterialSelectInput
								error={!!error}
								data-test={field.name}
								label={t('order_type_label')}
								options={orderType.options}
								disabled={!orderType.ready}
								required
								{...field}
							/>
						)}
					/>
					<Controller
						mandatory
						name={toPath(path, 'nameVisibility')}
						render={({ field, fieldState: { error } }) => (
							<MaterialSelectInput
								required
								error={!!error}
								data-test={field.name}
								label={t('name')}
								options={nameVisibility.options}
								disabled={!nameVisibility.ready}
								{...field}
							/>
						)}
					/>
					{environment.disabled ? (
						<SectionGridContent mandatory>
							<SectionGridLabel>{t('environment')}</SectionGridLabel>
							<SectionGridText data-test="additional-information-section-environment">
								{tEnvironment(t, environmentValue)}
							</SectionGridText>
						</SectionGridContent>
					) : (
						<Controller
							mandatory
							name={toPath(path, 'environment')}
							render={({ field, fieldState: { error } }) => (
								<MaterialSelectInput
									required
									error={!!error}
									data-test={field.name}
									label={t('environment')}
									options={environment.options}
									disabled={!environment.ready}
									{...field}
								/>
							)}
						/>
					)}
					<Controller
						mandatory
						name={toPath(path, 'role')}
						render={({ field, fieldState: { error } }) => (
							<MaterialSelectInput
								required
								error={!!error}
								data-test={field.name}
								label={t('role')}
								options={role.options}
								disabled={!role.ready}
								{...field}
							/>
						)}
					/>
					{counterparties.visible && (
						<Controller
							mandatory
							fullWidth
							name={toPath(path, 'counterparties')}
							render={({ field, fieldState: { error } }) => (
								<Styled.WideField>
									<MaterialMultiSelectInput
										key={`counterparties-dropdown${
											counterparties.ready ? '' : '-loading'
										}`}
										error={!!error}
										required
										dataTest={field.name}
										label={t('counterparties')}
										options={counterparties.options}
										groups={counterparties.groups}
										disabled={!counterparties.ready}
										OptionsWrapper={UpwardOptionsWrapper}
										{...field}
									/>
								</Styled.WideField>
							)}
						/>
					)}
				</Styled.FieldContainer>

				<Styled.TotalQuantityWrapper>
					<TotalQuantity path={path} shouldRender={shouldDisplayTotalQuantity} />
				</Styled.TotalQuantityWrapper>
				{shouldShowReadOnlyCounterparties && (
					<CounterpartiesSection
						mandatory
						fullWidth
						withSeparator
						order={{
							recipients_list: getValues(toPath(path, 'recipients_list')),
							order_type: typeValue,
						}}
					/>
				)}
				{canRemove && (
					<Styled.RemoveButton onClick={onRemove}>
						<DeleteIcon />
						{t('delete_order')}
					</Styled.RemoveButton>
				)}
			</div>
		</>
	);
};
